import { ApiConfig } from '@dvag/te-template-manager-web-component';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { keycloakService } from './auth/keycloak.service';
import { mapping, templates } from './data';

export const apiConfig: ApiConfig = environment.inmemory
  ? {
      mapping,
      templates,
    }
  : {
      basePath: environment.backend.baseUrl,
      accessToken: () => firstValueFrom(keycloakService.getToken()),
    };
