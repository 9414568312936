export const RADIO_OPTIONS = [
  {
    value: 'KONTOART_EINZELKONTO',
    label: 'Einzelkonto',
  },
  {
    value: 'KONTOART_GEMEINSCHAFTSKONTO',
    label: 'Gemeinschaftskonto',
  },
];

export type MultiDropdownId = 'dxMultiDropdownGirokonto' | 'dxMultiDropdownSparbuch' | 'dxMultiDropdownBAV';
export const MULTI_DROPDOWN_OPTIONS: {
  id: MultiDropdownId;
  formControlName: string;
  label: string;
}[] = [
  {
    id: 'dxMultiDropdownGirokonto',
    formControlName: 'dxMultiDropdownGirokonto',
    label: 'Girokonto',
  },
  {
    id: 'dxMultiDropdownSparbuch',
    formControlName: 'dxMultiDropdownSparbuch',
    label: 'Sparbuch',
  },
  {
    id: 'dxMultiDropdownBAV',
    formControlName: 'dxMultiDropdownBAV',
    label: 'Betriebliche Altersvorsorge',
  },
];
