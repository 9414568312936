import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FirstPageComponent } from './first-page/first-page.component';
import { FormComponent } from './form/form.component';
import {
  PATH_COPY_TEMPLATE,
  PATH_FORM,
  PATH_FORM_PAGE1,
  PATH_FORM_PAGE2,
  PATH_WARENKORB,
  SUBPATH_FORM_PAGE1,
  SUBPATH_FORM_PAGE2,
} from './navigation/routes';
import { SecondPageComponent } from './second-page/second-page.component';
import { TemplateShareLandingPageComponent } from './template-share-landing-page/template-share-landing-page.component';
import { WarenkorbComponent } from './warenkorb/warenkorb.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: PATH_FORM_PAGE1,
    pathMatch: 'full',
  },
  {
    path: PATH_WARENKORB,
    component: WarenkorbComponent,
  },
  {
    path: PATH_FORM,
    component: FormComponent,
    children: [
      {
        path: SUBPATH_FORM_PAGE1,
        component: FirstPageComponent,
      },
      {
        path: SUBPATH_FORM_PAGE2,
        component: SecondPageComponent,
      },
    ],
  },
  {
    path: PATH_COPY_TEMPLATE,
    redirectTo: `${PATH_COPY_TEMPLATE}/`,
    pathMatch: 'full',
  },
  {
    path: `${PATH_COPY_TEMPLATE}/:code`,
    component: TemplateShareLandingPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
