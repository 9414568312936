// Vorarbeit für eine typensichere Schnittstelle in der Webkomponente. So oder so ähnlich könnten die Typen aussehen.

export interface StringItem {
  type: 'string';
  value: string;
  label: string;
}

export interface NumberItem {
  type: 'number';
  value: number;
  label: string;
}

export interface BooleanItem {
  type: 'boolean';
  value: boolean;
  label: string;
}

export interface DxDropdownItem {
  type: 'dx-dropdown';
  value: string;
  label: string;
  optionLabel: string;
}

export interface DxRadioButtonGroupItem {
  type: 'dx-radio-button-group';
  value: string;
  label: string;
  optionLabel: string;
}

export interface DxMultiDropdownItem {
  type: 'dx-multi-dropdown';
  label: string;
  options: DxMultiDropdownOption[];
}

export interface DxDateItem {
  type: 'dx-date';
  mode: 'day-month-year' | 'day-month' | 'month-year';
}

export interface DxMultiDropdownOption {
  id: string;
  label: string;
  selected: boolean;
}

export type Item =
  | StringItem
  | NumberItem
  | BooleanItem
  | DxDateItem
  | DxDropdownItem
  | DxRadioButtonGroupItem
  | DxMultiDropdownItem;

export interface DataIdTemplateItem {
  dataID: string;
  value: string;
  label: string;
  context?: string;
}

export function multiDropdownValue(options: DxMultiDropdownOption[]): string {
  return JSON.stringify(
    options.map((o) => ({
      id: o.id,
      selected: o.selected,
    }))
  );
}

export function multiDropdownContext(options: DxMultiDropdownOption[]): string {
  return JSON.stringify({ options });
}

export function unwrapBoolean(value?: string): boolean | undefined {
  if (value === null || value === undefined) {
    return value;
  }

  return Boolean(value);
}

export function unwrapNumber(value?: string): number | undefined {
  if (value === null || value === undefined) {
    return value;
  }

  return Number(value);
}

export function unwrapMultiDropdownItems(value?: string): Map<string, { id: string; selected: boolean }> {
  if (value === null || value === undefined) {
    return new Map();
  }

  const itemsList: any[] = JSON.parse(value);
  return new Map(itemsList.map((item) => [item.id, item]));
}

export function nonNull<T>(t: T | null): t is T {
  return t !== null;
}
