<dx-process-header headline="Vorlage übernehmen">
  <te-template-manager slot="people-display" [config]="templateManagerConfig" #templateManager></te-template-manager>
</dx-process-header>

<dx-card>
  <dx-card-header label="Geteilte Vorlage übernehmen"></dx-card-header>
  <dx-card-content>
    <dx-text>Eine Vorlage wurde mit Ihnen geteilt.</dx-text>

    <div class="horizontal-row justify-right">
      <dx-button
        id="zurueck-zum-warenkorb"
        type="primary-s"
        label="Zurück zum Warenkorb"
        iconposition="right"
        icon="zurueck"
        (click)="zurueckZumWarenkorb()"
      ></dx-button>
    </div>
  </dx-card-content>
</dx-card>
