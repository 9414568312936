<dx-process-header headline="Beispielformular">
  <te-template-manager
    slot="people-display"
    [config]="templateManagerConfig"
    [activeTemplateId]="activeTemplateId"
    #templateManager
  ></te-template-manager>
</dx-process-header>

<dx-process-header>
  <dx-text slot="people-display">Max Mustermann</dx-text>
</dx-process-header>

<app-navigation>
  <router-outlet></router-outlet>
</app-navigation>
