<te-outdated-template-card>
  <dx-spacer mq1="16v" slot="bottom-spacing"></dx-spacer>
</te-outdated-template-card>

<form [formGroup]="beispielForm">
  <dx-grid mq1="12/*" rowgap="16">
    <dx-card>
      <dx-card-header label="Info"></dx-card-header>
      <dx-card-content>
        <dx-text>
          Dieses Formular dient als Beispiel für die Integration von Tarifrechner und Vorlagenverwaltung.
        </dx-text>
      </dx-card-content>
    </dx-card>

    <dx-card>
      <dx-card-collapsable id="basic" open="true">
        <dx-card-header label="Pflichtbereich" slot="header"></dx-card-header>
        <dx-card-content>
          <dx-grid mq1="12/*" rowgap="48">
            <dx-text type="infotext-standard"
              >Dieser Bereich kann eingeklappt werden, bleibt aber im Formular vorhanden.</dx-text
            >

            <dx-grid mq1="12/*" rowgap="4">
              <dx-dropdown
                formControlName="dxDropdown"
                id="dxDropdown"
                label="dx-dropdown (z.B. Tätigkeit)"
                [templatevalue]="(templateData$ | async)?.dxDropdown"
              >
                <dx-dropdown-option
                  *ngFor="let item of dropdownOptions"
                  [label]="item.label"
                  [value]="item.value"
                ></dx-dropdown-option>
              </dx-dropdown>
              <dx-text type="infotext-small"
                >Ein dx-dropdown wird verwendet um eine von mehreren Optionen auszuwählen. Der angezeigte Text und der
                technische Wert sind nicht unbedingt gleich.</dx-text
              >
            </dx-grid>

            <dx-grid mq1="12/*" rowgap="4">
              <dx-text-input
                formControlName="dxTextInput"
                id="dxTextInput"
                label="dx-text-input (z.B. Straße)"
                [templatevalue]="(templateData$ | async)?.dxTextInput"
              ></dx-text-input>
              <dx-text type="infotext-small"
                >Ein dx-text-input wird verwendet um einen kurzen Texte ohne Zeilenumbrüche zu erfassen.</dx-text
              >
            </dx-grid>

            <dx-grid mq1="12/*" rowgap="4">
              <dx-text-area
                formControlName="dxTextArea"
                id="dxTextArea"
                label="dx-text-area (z.B. Kommentar)"
                [templatevalue]="(templateData$ | async)?.dxTextArea"
              ></dx-text-area>
              <dx-text type="infotext-small"
                >Ein dx-text-area wird verwendet um einen längeren Text mit Zeilenumbrüchen zu erfassen.</dx-text
              >
            </dx-grid>

            <dx-grid mq1="12/*" rowgap="4">
              <dx-combobox
                label="dx-combobox (z.B. Land)"
                formControlName="dxCombobox"
                id="dxCombobox"
                (search)="provideValues($event)"
                [templatevalue]="(templateData$ | async)?.dxCombobox"
              ></dx-combobox>
              <dx-text type="infotext-small"
                >Eine dx-combobox wird verwendet um eine von mehreren Optionen auszuwählen</dx-text
              >
            </dx-grid>
          </dx-grid>
        </dx-card-content>
      </dx-card-collapsable>
    </dx-card>

    <dx-card>
      <dx-card-header label="Optionaler Bereich" slot="header">
        <dx-checkbox
          id="dxCardHeaderSelected"
          formControlName="dxCardHeaderSelected"
          slot="action"
          [templatevalue]="(templateData$ | async)?.dxCardHeaderSelected"
        ></dx-checkbox>
      </dx-card-header>
      <dx-card-content *ngIf="beispielForm.controls.dxCardHeaderSelected.value === true">
        <dx-grid mq1="12/*" rowgap="48">
          <dx-text type="infotext-standard"
            >Dieser Bereich kann abgewählt werden, und wird dabei komplett aus dem Formular entfernt.</dx-text
          >

          <dx-grid mq1="12/*" rowgap="4">
            <dx-form-wrapper label="dx-yes-no (z.B. “sind Sie über 18 Jahre alt?”)">
              <!-- yes-no has no fromtemplate attribute -->
              <dx-yes-no formControlName="dxYesNo" id="dxYesNo"></dx-yes-no>
            </dx-form-wrapper>
            <dx-text type="infotext-small">Ein dx-yes-no wird verwendet um einer Option zuzustimmen.</dx-text>
          </dx-grid>
        </dx-grid>
      </dx-card-content>
    </dx-card>

    <div class="right-aligned-row">
      <dx-button id="clearBtn" (click)="clearBtn()" label="Löschen" type="secondary-m"></dx-button>
      <dx-spacer mq1="8h"></dx-spacer>
      <dx-button id="nextBtn" (click)="nextPage()" label="weiter" type="primary-m"></dx-button>
    </div>
  </dx-grid>
</form>
