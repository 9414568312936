export const PATH_WARENKORB = 'warenkorb';
export const PATH_FORM = 'formular';
export const SUBPATH_FORM_PAGE1 = 'erste-seite';
export const SUBPATH_FORM_PAGE2 = 'zweite-seite';
export const PATH_FORM_PAGE1 = `${PATH_FORM}/${SUBPATH_FORM_PAGE1}`;
export const PATH_FORM_PAGE2 = `${PATH_FORM}/${SUBPATH_FORM_PAGE2}`;
export const PARAM_FORM_PRESELECTED_TEMPLATE_ID = 'preselectedTemplateId';
export const PARAM_FORM_TARIFIERUNG_NEU = 'tarifierungNeu';

export const PATH_COPY_TEMPLATE = 'vorlage-uebernehmen';
