import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FirstPageData } from './firstPage/interfaces';
import { DROPDOWN_OPTIONS } from './firstPage/options';
import { SecondPageData } from './secondPage/interfaces';
import { RADIO_OPTIONS } from './secondPage/options';

export interface Data {
  firstPage: FirstPageData;
  secondPage: SecondPageData;
}

export interface TemplateData {
  firstPage: Partial<FirstPageData>;
  secondPage: Partial<SecondPageData>;
}

export interface State {
  activeTemplateId: string | undefined;
  data: Data;
  templateData: TemplateData;
}

export const DEFAULT_STATE: State = {
  activeTemplateId: undefined,
  data: {
    firstPage: {
      dxDropdown: DROPDOWN_OPTIONS[0].value,
      dxTextInput: '',
      dxTextArea: '',
      dxCombobox: '',
      dxCardHeaderSelected: true,
      dxYesNo: 'no',
    },
    secondPage: {
      dxNumberInput: null,
      dxSlider: 20000,
      dxToggle: false,
      dxCheckbox: false,
      dxRadioButton: RADIO_OPTIONS[0].value,
      dxDateInput: '',
      dxDateRangeFrom: '',
      dxDateRangeTo: '',
      dxMultiDropdownGirokonto: false,
      dxMultiDropdownSparbuch: false,
      dxMultiDropdownBAV: false,
    },
  },
  templateData: {
    firstPage: {
      dxDropdown: undefined,
      dxTextInput: undefined,
      dxTextArea: undefined,
      dxCombobox: undefined,
      dxCardHeaderSelected: undefined,
      dxYesNo: undefined,
    },
    secondPage: {
      dxNumberInput: undefined,
      dxSlider: undefined,
      dxToggle: undefined,
      dxCheckbox: undefined,
      dxRadioButton: undefined,
      dxDateInput: undefined,
      dxDateRangeFrom: undefined,
      dxDateRangeTo: undefined,
      dxMultiDropdownGirokonto: undefined,
      dxMultiDropdownSparbuch: undefined,
      dxMultiDropdownBAV: undefined,
    },
  },
};

const STATE = 'state_v3';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  state$: BehaviorSubject<State>;

  constructor() {
    this.state$ = new BehaviorSubject(this.load());
  }

  updateFirstPage(firstPage: Partial<FirstPageData>) {
    const prevState = this.state$.value;
    this.state$.next({
      ...prevState,
      data: {
        ...prevState.data,
        firstPage: {
          ...prevState.data.firstPage,
          ...firstPage,
        },
      },
    });
    this.save();
  }

  updateSecondPage(secondPage: Partial<SecondPageData>) {
    const prevState = this.state$.value;
    this.state$.next({
      ...prevState,
      data: {
        ...prevState.data,
        secondPage: {
          ...prevState.data.secondPage,
          ...secondPage,
        },
      },
    });
    this.save();
  }

  selectTemplate(activeTemplateId: string, firstPage: Partial<FirstPageData>, secondPage: Partial<SecondPageData>) {
    const prevState = this.state$.value;
    const newState: State = {
      activeTemplateId,
      // only add values to existing data
      data: {
        ...prevState.data,
        firstPage: {
          ...prevState.data.firstPage,
          ...firstPage,
        },
        secondPage: {
          ...prevState.data.secondPage,
          ...secondPage,
        },
      },
      // completely replace template data
      templateData: {
        firstPage,
        secondPage,
      },
    };
    this.state$.next(newState);
    this.save();
  }

  restoreTemplate(activeTemplateId: string, firstPage: Partial<FirstPageData>, secondPage: Partial<SecondPageData>) {
    const prevState = this.state$.value;
    const newState: State = {
      activeTemplateId,
      // keep existing data
      data: prevState.data,
      // completely replace template data
      templateData: {
        firstPage,
        secondPage,
      },
    };
    this.state$.next(newState);
    this.save();
  }

  reset() {
    this.state$.next(DEFAULT_STATE);
    this.save();
  }

  private load(): State {
    return JSON.parse(localStorage.getItem(STATE) || 'null') ?? DEFAULT_STATE;
  }

  private save() {
    localStorage.setItem(STATE, JSON.stringify(this.state$.value));
  }
}
