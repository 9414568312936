import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, map } from 'rxjs';
import { PATH_FORM_PAGE2 } from '../navigation/routes';
import { FirstPageData } from '../service/state/firstPage/interfaces';
import { COMBOBOX_OPTIONS, DROPDOWN_OPTIONS } from '../service/state/firstPage/options';
import { DEFAULT_STATE, StateService } from '../service/state/state.service';

const DEFAULT_VALUES = DEFAULT_STATE.data.firstPage;

@Component({
  selector: 'app-first-page',
  templateUrl: './first-page.component.html',
  styleUrls: ['./first-page.component.scss'],
})
export class FirstPageComponent implements OnInit {
  dropdownOptions = DROPDOWN_OPTIONS;

  beispielForm = this.fb.group({
    dxDropdown: [DEFAULT_VALUES.dxDropdown],
    dxTextInput: [DEFAULT_VALUES.dxTextInput],
    dxTextArea: [DEFAULT_VALUES.dxTextArea],
    dxCombobox: [DEFAULT_VALUES.dxCombobox],
    dxCardHeaderSelected: [DEFAULT_VALUES.dxCardHeaderSelected],
    dxYesNo: [DEFAULT_VALUES.dxYesNo],
  });

  templateData$: Observable<Partial<FirstPageData>>;

  constructor(private fb: FormBuilder, private router: Router, private stateService: StateService) {}

  nextPage() {
    this.router.navigate([PATH_FORM_PAGE2]);
  }

  ngOnInit() {
    // provide easier access to fromTemplate state
    this.templateData$ = this.stateService.state$.pipe(map((state) => state.templateData.firstPage));

    // patch form on state changes
    this.stateService.state$.subscribe((val) => this.beispielForm.patchValue(val.data.firstPage, { emitEvent: false }));

    // update state on form changes
    this.beispielForm.valueChanges.subscribe((val) => this.stateService.updateFirstPage(val));
  }

  provideValues(event: any) {
    event.detail.provideValues([
      {
        ...COMBOBOX_OPTIONS[0],
        options: COMBOBOX_OPTIONS[0].options.filter((o) =>
          o.label.toLowerCase().includes(event.detail.value.toLowerCase().trim())
        ),
      },
    ]);
  }

  clearBtn() {
    this.stateService.reset();
  }
}
