<te-outdated-template-card>
  <dx-spacer mq1="16v" slot="bottom-spacing"></dx-spacer>
</te-outdated-template-card>

<form [formGroup]="beispielForm">
  <dx-grid mq1="12/*" rowgap="16">
    <dx-card>
      <dx-card-header label="Info"></dx-card-header>
      <dx-card-content>
        <dx-text>
          Dieses Formular dient als Beispiel für die Integration von Tarifrechner und Vorlagenverwaltung.
        </dx-text>
      </dx-card-content>
    </dx-card>

    <dx-card>
      <dx-card-collapsable id="basic" open="true">
        <dx-card-header label="Weitere Felder" slot="header"></dx-card-header>
        <dx-card-content>
          <dx-grid mq1="12/*" rowgap="48">
            <dx-grid mq1="12/*" rowgap="4">
              <dx-number-input
                formControlName="dxNumberInput"
                id="dxNumberInput"
                label="dx-number-input (z.B. Gehalt)"
                profile="currency-euro"
                precision="2"
                [templatevalue]="(templateData$ | async)?.dxNumberInput"
              ></dx-number-input>
              <dx-text type="infotext-small"
                >Ein dx-number-input wird verwendet um Zahlen zu erfassen. Um die Komplexität zu verringern kann die
                Vorlagenverwaltung die Formattierung im Formulars nur bedingt abbilden.</dx-text
              >
            </dx-grid>

            <dx-grid mq1="12/*" rowgap="4">
              <dx-slider
                formControlName="dxSlider"
                id="dxSlider"
                min="20000"
                max="180000"
                showminmaxlabel
                formattingpattern="$0 €"
                label="dx-slider (z.B. Kreditrahmen)"
                [templatevalue]="(templateData$ | async)?.dxSlider"
              ></dx-slider>
              <dx-text type="infotext-small"
                >Ein dx-slider wird verwendet um Zahlen zu erfassen. Um die Komplexität zu verringern kann die
                Vorlagenverwaltung die Formattierung im Formulars nur bedingt abbilden.</dx-text
              >
            </dx-grid>

            <dx-grid mq1="12/*" rowgap="4">
              <dx-form-wrapper label="dx-toggle (z.B. Online-Postfach)">
                <dx-toggle
                  formControlName="dxToggle"
                  id="dxToggle"
                  [templatevalue]="(templateData$ | async)?.dxToggle"
                ></dx-toggle>
              </dx-form-wrapper>
              <dx-text type="infotext-small"
                >Ein dx-toggle wird verwendet um eine Option zu (de-)aktivieren. Um die Komplexität zu verringern
                unterscheidet die Vorlagenverwaltung nicht zwischen dx-toggle und dx-checkbox.</dx-text
              >
            </dx-grid>

            <dx-grid mq1="12/*" rowgap="4">
              <dx-checkbox
                formControlName="dxCheckbox"
                id="dxCheckbox"
                label="dx-checkbox (z.B. Dispokredit)"
                [templatevalue]="(templateData$ | async)?.dxCheckbox"
              ></dx-checkbox>
              <dx-text type="infotext-small"
                >Eine dx-checkbox wird verwendet um eine Option zu (de-)aktivieren. Um die Komplexität zu verringern
                unterscheidet die Vorlagenverwaltung nicht zwischen dx-toggle und dx-checkbox.</dx-text
              >
            </dx-grid>

            <dx-grid mq1="12/*" rowgap="4">
              <dx-radio-button-group
                id="dxRadioButton"
                formControlName="dxRadioButton"
                label="dx-radio-button (z.B. Kontoart)"
                [templatevalue]="(templateData$ | async)?.dxRadioButton"
              >
                <div>
                  <dx-radio-button
                    *ngFor="let item of radioOptions"
                    [label]="item.label"
                    [value]="item.value"
                  ></dx-radio-button>
                </div>
              </dx-radio-button-group>
              <dx-text type="infotext-small"
                >Eine dx-radio-button-group wird verwendet um eine von mehreren Optionen auszuwählen. Der angezeigte
                Text und der technische Wert sind nicht unbedingt gleich.</dx-text
              >
            </dx-grid>

            <dx-grid mq1="12/*" rowgap="4">
              <dx-date-input
                formControlName="dxDateInput"
                id="dxDateInput"
                label="dx-date-input (z.B. Vertragsbeginn)"
                [templatevalue]="(templateData$ | async)?.dxDateInput"
              ></dx-date-input>
              <dx-text type="infotext-small"
                >Ein dx-date-input wird verwendet um ein Datum (z.B. 01.01.2022), einen Monat (01.2022), oder einen Tag
                (z.B. 01.01) auszuwählen.</dx-text
              >
            </dx-grid>

            <dx-grid mq1="12/*" rowgap="4">
              <dx-form-wrapper label="dx-date-range (z.B. Gültigkeitszeitraum)">
                <dx-date-range
                  id="dxDateRange"
                  [templatevalue]="
                    dateRange((templateData$ | async)?.dxDateRangeFrom, (templateData$ | async)?.dxDateRangeTo)
                  "
                >
                  <dx-date-input formControlName="dxDateRangeFrom" slot="from"></dx-date-input>
                  <dx-date-input formControlName="dxDateRangeTo" slot="to"></dx-date-input>
                </dx-date-range>
                <dx-text type="infotext-small"
                  >Ein dx-date-range wird verwendet um ein Datumsbereich (z.B. 01.01.2022 - 31.03.2022), einen
                  Monatsbereich (01.2022 - 06.2022), oder einen Tagesbereich (z.B. 01.01 - 31.03) auszuwählen.</dx-text
                >
              </dx-form-wrapper>
            </dx-grid>

            <dx-grid mq1="12/*" rowgap="4">
              <dx-multi-dropdown label="dx-multi-dropdown (z.B. Produkte)" id="dxMultiDropdown" icon="ordner">
                <dx-multi-dropdown-option
                  *ngFor="let item of multiDropdownOptions"
                  [formControlName]="item.formControlName"
                  [id]="item.id"
                  [label]="item.label"
                  [templatevalue]="findMultiDropdownTemplateData(item.id, templateData$ | async)"
                ></dx-multi-dropdown-option>
              </dx-multi-dropdown>
              <dx-text type="infotext-small"
                >Ein dx-multi-dropdown wird verwendet um mehrere Optionen gleichzeitig auszuwählen. Die angezeigten
                Texte und die technischen Werte sind nicht unbedingt gleich.</dx-text
              >
            </dx-grid>
          </dx-grid>
        </dx-card-content>
      </dx-card-collapsable>
    </dx-card>

    <div class="right-aligned-row">
      <dx-button id="clearBtn" (click)="clearBtn()" label="Löschen" type="secondary-m"></dx-button>
      <dx-spacer mq1="8h"></dx-spacer>
      <dx-button id="backBtn" (click)="goBack()" label="Zurück" type="primary-m"></dx-button>
    </div>
    <div style="display: flex; flex-direction: row-reverse; gap: 10px"></div>
  </dx-grid>
</form>

<te-save-card></te-save-card>
