import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TeilenService {
  @Output() createTemplateFromShareCodeRequested = new EventEmitter<string>();
  @Output() createTemplateFromShareCodeInProgress = new EventEmitter<boolean>();

  async requestCreateTemplateFromShareCode(code: string) {
    this.createTemplateFromShareCodeRequested.emit(code);
  }

  async reportCreateTemplateFromShareCodeInProgress(inProgress: boolean) {
    this.createTemplateFromShareCodeInProgress.emit(inProgress);
  }
}
