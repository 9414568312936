import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { mergeMap, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { KeycloakService } from './keycloak.service';

@Injectable({
  providedIn: 'root',
})
export class AuthHeaderInterceptor implements HttpInterceptor {
  constructor(private keycloak: KeycloakService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.url.startsWith(environment.keycloak.baseUrl)) {
      return next.handle(req);
    }
    return this.keycloak.getToken().pipe(
      mergeMap((token) => {
        return next.handle(req.clone({ setHeaders: { Authorization: 'bearer ' + token } }));
      })
    );
  }
}
