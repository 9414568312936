import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { PATH_FORM_PAGE1, PATH_FORM_PAGE2, PATH_WARENKORB } from './routes';
import { NavigateEventDetail } from '@dvag/design-system';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  selectedPage?: string;

  pages = [
    {
      label: 'Erste Seite',
      value: 'page1',
      path: PATH_FORM_PAGE1,
    },
    {
      label: 'Zweite Seite',
      value: 'page2',
      path: PATH_FORM_PAGE2,
    },
  ];

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((e) => this.updateSelectedPage(e as any));
  }

  private updateSelectedPage(e: NavigationEnd) {
    this.selectedPage = this.pages.find((p) => `/${p.path}` === e.urlAfterRedirects)?.value;
  }

  navigate(rawEvent: Event) {
    // not sure why Typescript can't infer CustomEvent<NavigateEventDetail> anymore...
    const e = rawEvent as unknown as CustomEvent<NavigateEventDetail>;
    const path = this.pages.find((p) => p.value === e.detail.value)?.path;
    if (path !== undefined) {
      this.router.navigateByUrl(path);
    }
  }
}
