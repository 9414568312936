export const DROPDOWN_OPTIONS = [
  {
    value: 'TAETIGKEIT_ANGESTELLTER',
    label: 'Angestellter',
  },
  {
    value: 'TAETIGKEIT_SELBSTSTAENDIG',
    label: 'Selbstständig',
  },
];

export const COMBOBOX_OPTIONS = [
  {
    label: null,
    options: [{ label: 'Deutschland' }, { label: 'Frankreich' }, { label: 'Spanien' }],
  },
];
