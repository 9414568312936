import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Config } from '@dvag/te-template-manager-web-component';
import { apiConfig } from '../config';
import { mappingId } from '../data';
import { PATH_COPY_TEMPLATE, PATH_WARENKORB } from '../navigation/routes';
import { TeilenService } from '../service/teilen.service';

@Component({
  selector: 'app-template-share-landing-page',
  templateUrl: './template-share-landing-page.component.html',
  styleUrls: ['./template-share-landing-page.component.scss'],
})
export class TemplateShareLandingPageComponent implements AfterViewInit {
  @ViewChild('templateManager') templateManager: ElementRef;

  templateManagerConfig: Config = {
    api: apiConfig,
    mapping: mappingId,
    warenkorb: false,
    tarifierungNeu: false,
  };

  constructor(private router: Router, private route: ActivatedRoute, private teilen: TeilenService) {}

  ngAfterViewInit(): void {
    this.route.params.subscribe(async (params) => {
      const code = params['code'];
      if (code !== undefined) {
        await this.templateManager.nativeElement.createTemplateFromShareCode(code);
        await this.templateManager.nativeElement.refresh();
      } else {
        console.warn(`Please supply a share code in the URL: ${PATH_COPY_TEMPLATE}/<uuid>`);
      }
    });
  }

  zurueckZumWarenkorb() {
    this.router.navigate([PATH_WARENKORB]);
  }
}
