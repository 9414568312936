import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, map } from 'rxjs';
import { PATH_FORM_PAGE1 } from '../navigation/routes';
import { SecondPageData } from '../service/state/secondPage/interfaces';
import { MULTI_DROPDOWN_OPTIONS, MultiDropdownId, RADIO_OPTIONS } from '../service/state/secondPage/options';
import { DEFAULT_STATE, StateService } from '../service/state/state.service';

const DEFAULT_VALUES = DEFAULT_STATE.data.secondPage;

@Component({
  selector: 'app-second-page',
  templateUrl: './second-page.component.html',
  styleUrls: ['./second-page.component.scss'],
})
export class SecondPageComponent implements OnInit {
  radioOptions = RADIO_OPTIONS;
  multiDropdownOptions = MULTI_DROPDOWN_OPTIONS;
  beispielForm = this.fb.group({
    dxNumberInput: [DEFAULT_VALUES.dxNumberInput],
    dxSlider: [DEFAULT_VALUES.dxSlider],
    dxToggle: [DEFAULT_VALUES.dxToggle],
    dxCheckbox: [DEFAULT_VALUES.dxCheckbox],
    dxRadioButton: [DEFAULT_VALUES.dxRadioButton],
    dxDateInput: [DEFAULT_VALUES.dxDateInput],
    dxDateRangeFrom: [DEFAULT_VALUES.dxDateRangeFrom],
    dxDateRangeTo: [DEFAULT_VALUES.dxDateRangeTo],
    dxMultiDropdownGirokonto: [DEFAULT_VALUES.dxMultiDropdownGirokonto],
    dxMultiDropdownSparbuch: [DEFAULT_VALUES.dxMultiDropdownSparbuch],
    dxMultiDropdownBAV: [DEFAULT_VALUES.dxMultiDropdownBAV],
  });

  templateData$: Observable<Partial<SecondPageData>>;

  constructor(private fb: FormBuilder, private router: Router, private stateService: StateService) {}

  ngOnInit() {
    // provide easier access to fromTemplate state
    this.templateData$ = this.stateService.state$.pipe(map((state) => state.templateData.secondPage));

    // patch form on state changes
    this.stateService.state$.subscribe((val) =>
      this.beispielForm.patchValue(val.data.secondPage, { emitEvent: false })
    );

    // update state on form changes
    this.beispielForm.valueChanges.subscribe((val) => this.stateService.updateSecondPage(val));
  }

  goBack() {
    this.router.navigate([PATH_FORM_PAGE1]);
  }

  clearBtn() {
    this.stateService.reset();
  }

  dateRange(from: string | null | undefined, to: string | null | undefined) {
    return `${from || ''}-${to || ''}`;
  }

  findMultiDropdownTemplateData(id: MultiDropdownId, secondPageData: Partial<SecondPageData> | null) {
    if (!secondPageData) {
      return undefined;
    }

    switch (id) {
      case 'dxMultiDropdownBAV':
        return secondPageData.dxMultiDropdownBAV;
      case 'dxMultiDropdownGirokonto':
        return secondPageData.dxMultiDropdownGirokonto;
      case 'dxMultiDropdownSparbuch':
        return secondPageData.dxMultiDropdownSparbuch;
      default:
        return undefined;
    }
  }
}
