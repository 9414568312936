<dx-app>
  <dx-header appname="Web VP">
    <dx-header-menu-item slot="menu" icon="einkaufen" (click)="goToWarenkorb()"></dx-header-menu-item>
    <dx-header-menu-link slot="menu" label="Ausloggen" (click)="logout()"></dx-header-menu-link>
  </dx-header>

  <dx-container type="page" color="background">
    <router-outlet></router-outlet>
  </dx-container>

  <dx-footer fromcolor="background"></dx-footer>
</dx-app>
