import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import Keycloak from 'keycloak-js';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class KeycloakService {
  private instance;

  constructor() {
    this.instance = new Keycloak({
      url: environment.keycloak.baseUrl,
      realm: environment.keycloak.realm,
      clientId: environment.keycloak.clientId,
    });
  }

  getToken(): Observable<string> {
    const updateToken = this.instance.updateToken(20);
    return new Observable((observer: Observer<string>) => {
      updateToken.then(
        (_: any) => {
          if (this.instance.token) {
            observer.next(this.instance.token);
          }
        },
        (data: any) => {
          observer.error(data);
          observer.complete();
        }
      );
    });
  }

  currentToken() {
    return this.instance.token || 'undefined';
  }

  initKeycloak() {
    const init = this.instance.init({
      onLoad: 'login-required',
      checkLoginIframe: true,
      checkLoginIframeInterval: 5,
    });

    return new Observable((observer: Observer<LoggedIn>) => {
      init.then(
        (_) => {
          return observer.next({
            success: true,
          });
        },
        (_) => {
          return observer.error({
            success: false,
          });
        }
      );
    });
  }

  logout() {
    this.instance.logout();
  }
}

export interface LoggedIn {
  success: boolean;
}

export const keycloakService = new KeycloakService();
