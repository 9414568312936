<dx-site-navigation>
  <dx-site-navigation-menu
    id="nav-menu"
    slot="menu"
    titlestring="Formularseite"
    [selectedvalue]="selectedPage"
    (navigate)="navigate($event)"
  >
    <dx-site-navigation-menu-item
      *ngFor="let page of pages"
      [label]="page.label"
      [value]="page.value"
    ></dx-site-navigation-menu-item>
  </dx-site-navigation-menu>

  <ng-content></ng-content>
</dx-site-navigation>
