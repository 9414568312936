import { MappingId } from '@dvag/te-template-manager-web-component/dist/types/shared/config';
import {
  Template,
  MappingResponse as Mapping,
} from '@dvag/te-template-manager-web-component/dist/types/shared/api/generated';

export const mapping: Mapping = {
  formID: 'VpDigitalTarifierungFormularTemplateBeispiel',
  version: '4.4.0',
  name: 'Vorbelegungsvorlagen Beispielanwendung',
  mappingInformations: [
    {
      formFieldID: 'firstPage',
      dataID: 'erste-seite',
      componentType: 'group',
      attributeName: 'unused',
      speicherbar: true,
      children: [
        {
          formFieldID: 'nav-menu-button-page1',
          dataID: 'nav-menu-button-page1',
          componentType: 'button',
          attributeName: 'unused',
          speicherbar: true,
        },
        {
          formFieldID: 'dxDropdown',
          dataID: 'dxDropdownID',
          componentType: 'dx-dropdown',
          attributeName: 'unused',
          speicherbar: true,
        },
        {
          formFieldID: 'dxTextInput',
          dataID: 'dxTextInputID',
          componentType: 'default',
          attributeName: 'unused',
          speicherbar: true,
        },
        {
          formFieldID: 'dxTextArea',
          dataID: 'dxTextAreaID',
          componentType: 'default',
          attributeName: 'unused',
          speicherbar: true,
        },
        {
          formFieldID: 'dxCombobox',
          dataID: 'dxComboboxID',
          componentType: 'default',
          attributeName: 'unused',
          speicherbar: true,
        },
        {
          formFieldID: 'dxCardHeaderSelected',
          dataID: 'dxCardHeaderSelectedID',
          componentType: 'checked',
          attributeName: 'unused',
          speicherbar: true,
          children: [
            {
              attributeName: 'unused',
              speicherbar: true,
              componentType: 'default',
              dataID: 'dxYesNoID',
              formFieldID: 'dxYesNo',
            },
          ],
        },
      ],
    },
    {
      formFieldID: 'secondPage',
      dataID: 'zweite-seite',
      componentType: 'group',
      attributeName: 'unused',
      speicherbar: true,
      children: [
        {
          formFieldID: 'nav-menu-button-page2',
          dataID: 'nav-menu-button-page2',
          componentType: 'button',
          attributeName: 'unused',
          speicherbar: true,
        },
        {
          formFieldID: 'cardCollapsable',
          dataID: 'null',
          componentType: 'group',
          attributeName: 'unused',
          speicherbar: true,
          children: [
            {
              formFieldID: 'dxNumberInput',
              dataID: 'dxNumberInputID',
              componentType: 'default',
              attributeName: 'unused',
              speicherbar: true,
            },
            {
              formFieldID: 'dxSlider',
              dataID: 'dxSliderID',
              componentType: 'default',
              attributeName: 'unused',
              speicherbar: true,
            },
            {
              formFieldID: 'dxToggle',
              dataID: 'dxToggleID',
              componentType: 'checked',
              attributeName: 'unused',
              speicherbar: true,
            },
            {
              formFieldID: 'dxCheckbox',
              dataID: 'dxCheckboxID',
              componentType: 'checked',
              attributeName: 'unused',
              speicherbar: true,
            },
            {
              formFieldID: 'dxRadioButton',
              dataID: 'dxRadioButtonID',
              componentType: 'dx-radio-button-group',
              attributeName: 'unused',
              speicherbar: true,
            },
            {
              formFieldID: 'dxDateInput',
              dataID: 'dxDateInputID',
              componentType: 'dx-date-input',
              attributeName: 'unused',
              speicherbar: false,
            },
            {
              attributeName: 'unused',
              speicherbar: false,
              componentType: 'dx-date-range',
              dataID: 'dxDateRangeID',
              formFieldID: 'dxDateRange',
            },
            {
              formFieldID: 'dxMultiDropdown',
              dataID: 'dxMultiDropdown',
              componentType: 'dx-multi-dropdown',
              attributeName: 'unused',
              speicherbar: true,
            },
          ],
        },
        {
          formFieldID: 'nav-menu-button-page1',
          dataID: 'nav-menu-button-page1',
          componentType: 'button',
          attributeName: 'unused',
          speicherbar: true,
        },
      ],
    },
  ],
};

export const mappingId: MappingId = {
  formId: mapping.formID,
  version: mapping.version,
};

export const templates: Template[] = [
  {
    id: '00000000-0000-0000-000000000001',
    formID: 'VpDigitalTarifierungFormularTemplateBeispiel',
    mappingVersion: mapping.version,
    name: 'Meine Vorlage 1',
    description: 'Beschreibung für Meine Vorlage 1',
    defaultTemplate: false,
    hasReference: false,
    createdAt: '1970-01-01T00:00:00Z',
    updatedAt: '1970-01-01T00:00:00Z',
    templateItemList: [
      { dataID: 'nav-menu-button-page1', value: 'unused', label: 'unused' },
      { dataID: 'nav-menu-button-page2', value: 'unused', label: 'unused' },
      { dataID: 'dxDropdownID', value: 'TAETIGKEIT_ANGESTELLTER', label: 'dxDropdown label' },
      { dataID: 'dxTextInputID', value: 'Text-8', label: 'dxTextInput label' },
      { dataID: 'dxRadioButtonID', value: 'KONTOART_EINZELKONTO', label: 'dxRadioButton label' },
      { dataID: 'dxDateInputID', value: '2007-07-06', label: 'dxDateInput label' },
      { dataID: 'dxComboboxID', value: 'Frankreich', label: 'dxCombobox label' },
      { dataID: 'dxCardHeaderSelectedID', value: 'true', label: 'dxCardHeaderSelected label' },
      { dataID: 'dxDateRangeID', value: '2021-02-03,2024-05-06', label: 'dxDateRange label' },
      { dataID: 'dxYesNoID', value: 'no', label: 'dxYesNo label' },
      { dataID: 'dxNumberInputID', value: '123.45', label: 'dxNumberInput label' },
      { dataID: 'dxSliderID', value: '30000', label: 'dxSlider label' },
      { dataID: 'dxToggleID', value: 'false', label: 'dxToggle label' },
      { dataID: 'dxTextAreaID', value: 'Beschreibung für\nMeine Vorlage 1', label: 'dxTextArea label' },
      { dataID: 'dxCheckboxID', value: 'true', label: 'dxCheckbox label' },
      {
        dataID: 'dxMultiDropdown',
        value: `[
          {
            "id": "dxMultiDropdownGirokonto",
            "selected": true
          },
          {
            "id": "dxMultiDropdownSparbuch",
            "selected": false
          },
          {
            "id": "dxMultiDropdownBAV",
            "selected": true
          }
        ]`,
        label: 'dxMultiDropdown label',
        context: `{
          "options": [
            {
              "id": "dxMultiDropdownGirokonto",
              "selected": true,
              "label": "Girokonto"
            },
            {
              "id": "dxMultiDropdownSparbuch",
              "selected": false,
              "label": "Sparbuch"
            },
            {
              "id": "dxMultiDropdownBAV",
              "selected": true,
              "label": "Betriebliche Altersvorsorge"
            }
          ]
        }`,
      },
    ],
  },
  {
    id: '00000000-0000-0000-000000000002',
    formID: 'VpDigitalTarifierungFormularTemplateBeispiel',
    mappingVersion: mapping.version,
    name: 'Meine Vorlage 2',
    description: 'Beschreibung für Meine Vorlage 2',
    defaultTemplate: false,
    hasReference: false,
    createdAt: '1970-01-01T00:00:00Z',
    updatedAt: '1970-01-01T00:00:00Z',
    templateItemList: [
      { dataID: 'nav-menu-button-page1', value: 'unused', label: 'unused' },
      { dataID: 'nav-menu-button-page2', value: 'unused', label: 'unused' },
      { dataID: 'dxDropdownID', value: '2', label: 'dxDropdown label' },
      { dataID: 'dxTextInputID', value: 'Text-9', label: 'dxTextInput label' },
      { dataID: 'dxRadioButtonID', value: 'no', label: 'dxRadioButton label' },
      { dataID: 'dxDateInputID', value: '2008-05-06', label: 'dxDateInput label' },
      { dataID: 'dxComboboxID', value: '', label: 'dxCombobox label' },
      { dataID: 'dxCardHeaderSelectedID', value: 'false', label: 'dxCardHeaderSelected label' },
      { dataID: 'dxDateRangeID', value: '', label: 'dxDateRange label' },
      { dataID: 'dxYesNoID', value: 'no', label: 'dxYesNo label' },
      { dataID: 'dxNumberInputID', value: '2022-11-28', label: 'dxNumberInput label' },
      { dataID: 'dxSliderID', value: '17000', label: 'dxSlider label' },
      { dataID: 'dxToggleID', value: 'false', label: 'dxToggle label' },
      { dataID: 'dxTextAreaID', value: 'Beschreibung für Meine Vorlage 2', label: 'dxTextArea label' },
      { dataID: 'dxCheckboxID', value: 'false', label: 'dxCheckbox label' },
      { dataID: 'dxMultiDropdown', value: '[]', label: 'dxMultiDropdown label' },
    ],
  },
  {
    id: '00000000-0000-0000-000000000003',
    formID: 'VpDigitalTarifierungFormularTemplateBeispiel',
    mappingVersion: mapping.version,
    name: 'Meine Vorlage 3',
    description: 'Beschreibung für Meine Vorlage 3',
    defaultTemplate: false,
    hasReference: false,
    createdAt: '1970-01-01T00:00:00Z',
    updatedAt: '1970-01-01T00:00:00Z',
    templateItemList: [
      { dataID: 'nav-menu-button-page1', value: 'unused', label: 'unused' },
      { dataID: 'nav-menu-button-page2', value: 'unused', label: 'unused' },
      { dataID: 'dxDropdownID', value: '2', label: 'dxDropdown label' },
      { dataID: 'dxTextInputID', value: 'Text-5', label: 'dxTextInput label' },
      { dataID: 'dxRadioButtonID', value: 'no', label: 'dxRadioButton label' },
      { dataID: 'dxDateInputID', value: '2011-05-06', label: 'dxDateInput label' },
      { dataID: 'dxComboboxID', value: 'Option 2', label: 'dxCombobox label' },
      { dataID: 'dxCardHeaderSelectedID', value: 'true', label: 'dxCardHeaderSelected label' },
      { dataID: 'dxDateRangeID', value: '', label: 'dxDateRange label' },
      { dataID: 'dxYesNoID', value: 'yes', label: 'dxYesNo label' },
      { dataID: 'dxNumberInputID', value: '2022-11-28', label: 'dxNumberInput label' },
      { dataID: 'dxSliderID', value: '18000', label: 'dxSlider label' },
      { dataID: 'dxToggleID', value: 'false', label: 'dxToggle label' },
      { dataID: 'dxTextAreaID', value: 'Beschreibung für Meine Vorlage 3', label: 'dxTextArea label' },
      { dataID: 'dxCheckboxID', value: 'false', label: 'dxCheckbox label' },
      { dataID: 'dxMultiDropdown', value: '[]', label: 'dxMultiDropdown label' },
    ],
    removedItems: [
      { dataID: 'dxTextInputID', value: 'Text aus alter Version der Tarifierung', label: 'Altes Textfeld' },
    ],
  },
  {
    id: '00000000-0000-0000-000000000004',
    formID: 'VpDigitalTarifierungFormularTemplateBeispiel',
    mappingVersion: mapping.version,
    name: 'Geteilte Vorlage 1',
    description: 'Beschreibung für Geteilte Vorlage 1',
    defaultTemplate: false,
    hasReference: false,
    referenceId: '00000000-0000-0000-0000-100000000001',
    createdAt: '1970-01-01T00:00:00Z',
    updatedAt: '1970-01-01T00:00:00Z',
    templateItemList: [
      { dataID: 'nav-menu-button-page1', value: 'unused', label: 'unused' },
      { dataID: 'nav-menu-button-page2', value: 'unused', label: 'unused' },
      { dataID: 'dxDropdownID', value: '2', label: 'dxDropdown label' },
      { dataID: 'dxTextInputID', value: 'Text-4', label: 'dxTextInput label' },
      { dataID: 'dxRadioButtonID', value: 'no', label: 'dxRadioButton label' },
      { dataID: 'dxDateInputID', value: '2020-08-06', label: 'dxDateInput label' },
      { dataID: 'dxComboboxID', value: 'Option 1', label: 'dxCombobox label' },
      { dataID: 'dxCardHeaderSelectedID', value: 'false', label: 'dxCardHeaderSelected label' },
      { dataID: 'dxDateRangeID', value: '2022-11-30,2022-12-22', label: 'dxDateRange label' },
      { dataID: 'dxYesNoID', value: 'no', label: 'dxYesNo label' },
      { dataID: 'dxNumberInputID', value: '2022-11-28', label: 'dxNumberInput label' },
      { dataID: 'dxSliderID', value: '98777', label: 'dxSlider label' },
      { dataID: 'dxToggleID', value: 'true', label: 'dxToggle label' },
      { dataID: 'dxTextAreaID', value: 'Beschreibung für Geteilte Vorlage 1', label: 'dxTextArea label' },
      { dataID: 'dxCheckboxID', value: 'true', label: 'dxCheckbox label' },
      { dataID: 'dxMultiDropdown', value: '[]', label: 'dxMultiDropdown label' },
    ],
  },
  {
    id: '00000000-0000-0000-000000000005',
    formID: 'VpDigitalTarifierungFormularTemplateBeispiel',
    mappingVersion: mapping.version,
    name: 'Geteilte Vorlage 2',
    description: 'Beschreibung für Geteilte Vorlage 2',
    defaultTemplate: false,
    hasReference: false,
    referenceId: '00000000-0000-0000-0000-100000000002',
    createdAt: '1970-01-01T00:00:00Z',
    updatedAt: '1970-01-01T00:00:00Z',
    templateItemList: [
      { dataID: 'nav-menu-button-page1', value: 'unused', label: 'unused' },
      { dataID: 'nav-menu-button-page2', value: 'unused', label: 'unused' },
      { dataID: 'dxDropdownID', value: '2', label: 'dxDropdown label' },
      { dataID: 'dxTextInputID', value: 'Text-3', label: 'dxTextInput label' },
      { dataID: 'dxRadioButtonID', value: 'no', label: 'dxRadioButton label' },
      { dataID: 'dxDateInputID', value: '2015-07-06', label: 'dxDateInput label' },
      { dataID: 'dxComboboxID', value: 'Option 2', label: 'dxCombobox label' },
      { dataID: 'dxCardHeaderSelectedID', value: 'false', label: 'dxCardHeaderSelected label' },
      { dataID: 'dxDateRangeID', value: '', label: 'dxDateRange label' },
      { dataID: 'dxYesNoID', value: 'no', label: 'dxYesNo label' },
      { dataID: 'dxNumberInputID', value: '2022-11-28', label: 'dxNumberInput label' },
      { dataID: 'dxSliderID', value: '98616', label: 'dxSlider label' },
      { dataID: 'dxToggleID', value: 'false', label: 'dxToggle label' },
      { dataID: 'dxTextAreaID', value: 'Beschreibung für Geteilte Vorlage 2', label: 'dxTextArea label' },
      { dataID: 'dxCheckboxID', value: 'true', label: 'dxCheckbox label' },
      { dataID: 'dxMultiDropdown', value: '[]', label: 'dxMultiDropdown label' },
    ],
  },
  {
    id: '00000000-0000-0000-000000000006',
    formID: 'VpDigitalTarifierungFormularTemplateBeispiel',
    mappingVersion: mapping.version,
    name: 'Geteilte Vorlage 3',
    description: 'Beschreibung für Geteilte Vorlage 3',
    defaultTemplate: false,
    hasReference: false,
    referenceId: '00000000-0000-0000-0000-100000000003',
    createdAt: '1970-01-01T00:00:00Z',
    updatedAt: '1970-01-01T00:00:00Z',
    templateItemList: [
      { dataID: 'nav-menu-button-page1', value: 'unused', label: 'unused' },
      { dataID: 'nav-menu-button-page2', value: 'unused', label: 'unused' },
      { dataID: 'dxDropdownID', value: '1', label: 'dxDropdown label' },
      { dataID: 'dxTextInputID', value: 'Text-2', label: 'dxTextInput label' },
      { dataID: 'dxRadioButtonID', value: 'yes', label: 'dxRadioButton label' },
      { dataID: 'dxDateInputID', value: '2006-06-06', label: 'dxDateInput label' },
      { dataID: 'dxComboboxID', value: 'Option 1', label: 'dxCombobox label' },
      { dataID: 'dxCardHeaderSelectedID', value: 'true', label: 'dxCardHeaderSelected label' },
      { dataID: 'dxDateRangeID', value: '2022-11-22,2022-12-22', label: 'dxDateRange label' },
      { dataID: 'dxYesNoID', value: 'yes', label: 'dxYesNo label' },
      { dataID: 'dxNumberInputID', value: '2022-12-28', label: 'dxNumberInput label' },
      { dataID: 'dxSliderID', value: '20000', label: 'dxSlider label' },
      { dataID: 'dxToggleID', value: 'true', label: 'dxToggle label' },
      { dataID: 'dxTextAreaID', value: 'Beschreibung für Geteilte Vorlage 3', label: 'dxTextArea label' },
      { dataID: 'dxCheckboxID', value: 'false', label: 'dxCheckbox label' },
      { dataID: 'dxMultiDropdown', value: '[]', label: 'dxMultiDropdown label' },
    ],
  },
];
