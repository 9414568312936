import { ApplicationRef, CUSTOM_ELEMENTS_SCHEMA, DoBootstrap, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DesignSystemModule } from '@dvag/design-system-angular';
import { defineCustomElements as loadDesignSystemIcons } from '@dvag/design-system-icons/loader';
import { defineCustomElements as loadVorlagen } from '@dvag/te-template-manager-web-component/loader';
import { merge } from 'lodash';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthHeaderInterceptor } from './auth/auth-header-interceptor';
import { KeycloakService, keycloakService } from './auth/keycloak.service';
import { FirstPageComponent } from './first-page/first-page.component';
import { FormComponent } from './form/form.component';
import { NavigationComponent } from './navigation/navigation.component';
import { SecondPageComponent } from './second-page/second-page.component';
import { TeilenService } from './service/teilen.service';
import { TemplateShareLandingPageComponent } from './template-share-landing-page/template-share-landing-page.component';
import { WarenkorbComponent } from './warenkorb/warenkorb.component';

@NgModule({
  declarations: [
    AppComponent,
    SecondPageComponent,
    FirstPageComponent,
    NavigationComponent,
    TemplateShareLandingPageComponent,
    WarenkorbComponent,
    FormComponent,
  ],
  imports: [BrowserModule, AppRoutingModule, DesignSystemModule, ReactiveFormsModule, FormsModule, HttpClientModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHeaderInterceptor,
      multi: true,
    },
    KeycloakService,
    TeilenService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule implements DoBootstrap {
  async ngDoBootstrap(appRef: ApplicationRef) {
    await Promise.all([this.loadConfig(), loadDesignSystemIcons(), loadVorlagen()]);

    if (environment.secure) {
      keycloakService.initKeycloak().subscribe((_) => {
        appRef.bootstrap(AppComponent);
      });
    } else {
      appRef.bootstrap(AppComponent);
    }
  }

  private async loadConfig() {
    const env = await (await fetch('/assets/config/config.json')).json();
    merge(environment, env || {});
  }
}
