export const environment = {
  production: true,
  environment: 'entwicklung',
  secure: true,
  inmemory: false,
  keycloak: {
    baseUrl: 'https://entwicklung.auth.dvag/auth',
    realm: 'zob',
    clientId: 'app-web-vp-digital-vorlagenverwaltung-beispiel',
  },
  backend: {
    baseUrl: 'https://vorbelegungsvorlagenverwaltung.entwicklung.dvag',
  },
};
