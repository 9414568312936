<dx-process-header headline="Warenkorb">
  <te-template-manager slot="people-display" [config]="templateManagerConfig" #templateManager></te-template-manager>
</dx-process-header>

<dx-card>
  <dx-card-header label="Info"></dx-card-header>
  <dx-card-content>
    <dx-grid mq1="12/*">
      <dx-text>
        Hier kann gesteuert werden, wie sich die Vorlagenverwaltung beim Betreten der Tarifierung verhält.
      </dx-text>

      <dx-text>
        Bei einer neuen Tarifierung wird die Standardvorlage des Vermögensberaters angewendet, sofern er eine hinterlegt
        hat.
      </dx-text>

      <dx-text> Wenn eine bestehende Tarifierung fortgesetzt wird, passiert nichts. </dx-text>

      <div class="horizontal-row justify-right">
        <dx-button
          id="neue-tarifierung"
          type="primary-s"
          label="Neue Tarifierung"
          iconposition="right"
          icon="plus-kreis"
          (click)="tarifierungNeu()"
        ></dx-button>
        <dx-spacer mq1="16"></dx-spacer>
        <dx-button
          id="tarifierung-bearbeiten"
          type="primary-s"
          label="Tarifierung Bearbeiten"
          iconposition="right"
          icon="bearbeiten"
          (click)="tarifierungForfuehren()"
        ></dx-button>
      </div>
    </dx-grid>
  </dx-card-content>
</dx-card>
