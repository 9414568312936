import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  Config,
  TemplateRequest,
  TemplateRestoredPayload,
  TemplateSelection,
} from '@dvag/te-template-manager-web-component';
import { Template } from '@dvag/te-template-manager-web-component/dist/types/shared/api/generated';
import { apiConfig } from '../config';
import { mappingId } from '../data';
import { PARAM_FORM_PRESELECTED_TEMPLATE_ID, PARAM_FORM_TARIFIERUNG_NEU } from '../navigation/routes';
import { FirstPageData } from '../service/state/firstPage/interfaces';
import { DROPDOWN_OPTIONS } from '../service/state/firstPage/options';
import { SecondPageData } from '../service/state/secondPage/interfaces';
import { MULTI_DROPDOWN_OPTIONS, RADIO_OPTIONS } from '../service/state/secondPage/options';
import { StateService } from '../service/state/state.service';
import {
  DataIdTemplateItem,
  DxMultiDropdownOption,
  multiDropdownContext,
  multiDropdownValue,
  nonNull,
  unwrapBoolean,
  unwrapMultiDropdownItems,
  unwrapNumber,
} from './utils';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements AfterViewInit {
  @ViewChild('templateManager') templateManager: ElementRef;

  templateManagerConfig?: Config = undefined;
  activeTemplateId: string | undefined = undefined;

  constructor(private route: ActivatedRoute, private stateService: StateService) {}

  ngAfterViewInit() {
    this.templateManager.nativeElement.addEventListener('templateSelected', this.templateSelected.bind(this));
    this.templateManager.nativeElement.addEventListener('templateRestored', this.templateRestored.bind(this));
    this.templateManager.nativeElement.addEventListener('templateRequested', this.templateRequested.bind(this));
    // angular doesn't like changing bound values in ngAfterViewInit, so configure in next tick with setTimeout(..., 0)
    this.route.queryParams.subscribe((params) =>
      setTimeout(() => {
        this.templateManagerConfig = {
          api: apiConfig,
          mapping: mappingId,
          warenkorb: false,
          preselectedTemplateId: params[PARAM_FORM_PRESELECTED_TEMPLATE_ID],
          tarifierungNeu:
            params[PARAM_FORM_TARIFIERUNG_NEU] !== undefined ? Boolean(params[PARAM_FORM_TARIFIERUNG_NEU]) : false,
        };
        this.stateService.state$.subscribe((state) => (this.activeTemplateId = state.activeTemplateId));
      }, 0)
    );
  }

  async templateSelected(event: CustomEvent<TemplateSelection>) {
    console.log('template selected', event.detail.template);
    await event.detail.templateFillStarted();
    const { firstPage, secondPage } = this.getStateFromTemplate(event.detail.template);
    this.stateService.selectTemplate(event.detail.template.id!, firstPage, secondPage);
    await event.detail.templateFillSuccess();
  }

  async templateRestored(event: CustomEvent<TemplateRestoredPayload>) {
    console.log('template restored', event.detail.template);
    const { firstPage, secondPage } = this.getStateFromTemplate(event.detail.template);
    this.stateService.restoreTemplate(event.detail.template.id!, firstPage, secondPage);
  }

  async templateRequested(event: CustomEvent<TemplateRequest>) {
    console.log('template requested');
    const items: DataIdTemplateItem[] = this.buildTemplateItems();
    const template: Template = {
      defaultTemplate: false,
      formID: 'ignored',
      mappingVersion: 'ignored',
      name: 'ignored',
      templateItemList: items,
    };
    await event.detail.supplyTemplate(template);
  }

  getStateFromTemplate(template: Template): {
    firstPage: Partial<FirstPageData>;
    secondPage: Partial<SecondPageData>;
  } {
    const items = new Map(template.templateItemList.map((item) => [item.dataID, item]));
    const dxMultiDropdownItems = unwrapMultiDropdownItems(items.get('dxMultiDropdown')?.value);
    const firstPage: Partial<FirstPageData> = {
      dxDropdown: items.get('dxDropdownID')?.value,
      dxTextInput: items.get('dxTextInputID')?.value,
      dxTextArea: items.get('dxTextAreaID')?.value,
      dxCombobox: items.get('dxComboboxID')?.value,
      dxCardHeaderSelected: unwrapBoolean(items.get('dxCardHeaderSelectedID')?.value),
      dxYesNo: items.get('dxYesNoID')?.value,
    };
    const secondPage: Partial<SecondPageData> = {
      dxNumberInput: unwrapNumber(items.get('dxNumberInputID')?.value),
      dxSlider: unwrapNumber(items.get('dxSliderID')?.value),
      dxToggle: unwrapBoolean(items.get('dxToggleID')?.value),
      dxCheckbox: unwrapBoolean(items.get('dxCheckboxID')?.value),
      dxRadioButton: items.get('dxRadioButtonID')?.value,
      dxDateInput: items.get('dxDateInputID')?.value,
      dxDateRangeFrom: items.get('dxDateRangeFromID')?.value,
      dxDateRangeTo: items.get('dxDateRangeToID')?.value,
      dxMultiDropdownGirokonto: dxMultiDropdownItems.get('dxMultiDropdownGirokonto')?.selected,
      dxMultiDropdownSparbuch: dxMultiDropdownItems.get('dxMultiDropdownSparbuch')?.selected,
      dxMultiDropdownBAV: dxMultiDropdownItems.get('dxMultiDropdownBAV')?.selected,
    };
    return { firstPage, secondPage };
  }

  buildTemplateItems(): DataIdTemplateItem[] {
    return [this.buildFirstPageItemsRaw(), this.buildSecondPageItemsRaw()].flatMap((x) => x);
  }

  buildFirstPageItemsRaw(): DataIdTemplateItem[] {
    const values = this.stateService.state$.value.data.firstPage;

    const items: (DataIdTemplateItem | null)[] = [
      // first page
      {
        dataID: 'erste-seite',
        value: '',
        label: 'Erste Seite',
      },
      values.dxDropdown != null
        ? {
            dataID: 'dxDropdownID',
            label: 'dx-dropdown (z.B. Tätigkeit)',
            value: values.dxDropdown,
            context: JSON.stringify({
              optionLabel: DROPDOWN_OPTIONS.find((o) => o.value === values.dxDropdown)?.label,
            }),
          }
        : null,
      values.dxTextInput != null
        ? { dataID: 'dxTextInputID', label: 'dx-text-input (z.B. Straße)', value: values.dxTextInput }
        : null,
      values.dxTextArea != null
        ? { dataID: 'dxTextAreaID', label: 'dx-text-area (z.B. Kommentar)', value: values.dxTextArea }
        : null,
      values.dxCombobox != null
        ? { dataID: 'dxComboboxID', label: 'dx-combobox (z.B. Land)', value: values.dxCombobox }
        : null,
      values.dxCardHeaderSelected != null
        ? { dataID: 'dxCardHeaderSelectedID', label: 'Optionaler Bereich', value: String(values.dxCardHeaderSelected) }
        : null,
      values.dxYesNo != null
        ? { dataID: 'dxYesNoID', label: 'dx-yes-no (z.B. “sind Sie über 18 Jahre alt?”)', value: values.dxYesNo }
        : null,
    ];
    return items.filter(nonNull);
  }

  buildSecondPageItemsRaw(): DataIdTemplateItem[] {
    const values = this.stateService.state$.value.data.secondPage;
    const dxMultiDropdownOptions: DxMultiDropdownOption[] = [
      {
        id: 'dxMultiDropdownGirokonto',
        selected: values.dxMultiDropdownBAV ?? false,
        label:
          MULTI_DROPDOWN_OPTIONS.find((o) => o.id === 'dxMultiDropdownGirokonto')?.label ?? 'dxMultiDropdownGirokonto',
      },
      {
        id: 'dxMultiDropdownSparbuch',
        selected: values.dxMultiDropdownGirokonto ?? false,
        label:
          MULTI_DROPDOWN_OPTIONS.find((o) => o.id === 'dxMultiDropdownSparbuch')?.label ?? 'dxMultiDropdownSparbuch',
      },
      {
        id: 'dxMultiDropdownBAV',
        selected: values.dxMultiDropdownSparbuch ?? false,
        label: MULTI_DROPDOWN_OPTIONS.find((o) => o.id === 'dxMultiDropdownBAV')?.label ?? 'dxMultiDropdownBAV',
      },
    ];
    return [
      // second page
      {
        dataID: 'zweite-seite',
        value: '',
        label: 'Zweite Seite',
      },
      values.dxNumberInput != null
        ? { dataID: 'dxNumberInputID', label: 'dx-number-input (z.B. Gehalt)', value: String(values.dxNumberInput) }
        : null,
      values.dxSlider != null
        ? { dataID: 'dxSliderID', label: 'dx-slider (z.B. Kreditrahmen)', value: String(values.dxSlider) }
        : null,
      values.dxToggle != null
        ? { dataID: 'dxToggleID', label: 'dx-toggle (z.B. Online-Postfach)', value: String(values.dxToggle) }
        : null,
      values.dxCheckbox != null
        ? { dataID: 'dxCheckboxID', label: 'dx-checkbox (z.B. Dispokredit)', value: String(values.dxCheckbox) }
        : null,
      values.dxRadioButton != null
        ? {
            dataID: 'dxRadioButtonID',
            label: 'dx-radio-button (z.B. Kontoart)',
            value: values.dxRadioButton,
            context: JSON.stringify({
              optionLabel: RADIO_OPTIONS.find((o) => o.value === values.dxRadioButton)?.label ?? values.dxRadioButton,
            }),
          }
        : null,
      values.dxDateInput != null
        ? {
            dataID: 'dxDateInputID',
            label: 'dx-date-input (z.B. Vertragsbeginn)',
            value: values.dxDateInput,
            context: JSON.stringify({
              mode: 'day-month-year',
            }),
          }
        : null,
      values.dxDateRangeFrom != null || values.dxDateRangeTo != null
        ? {
            dataID: 'dxDateRangeID',
            label: 'dx-date-range (z.B. Gültigkeitszeitraum)',
            value: `${values.dxDateRangeFrom ?? ''},${values.dxDateRangeTo ?? ''}`,
            context: JSON.stringify({
              mode: 'day-month-year',
            }),
          }
        : null,
      {
        dataID: 'dxMultiDropdown',
        label: 'dx-multi-dropdown (z.B. Produkte)',
        value: multiDropdownValue(dxMultiDropdownOptions),
        context: multiDropdownContext(dxMultiDropdownOptions),
      },
    ].filter(nonNull);
  }
}
