import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Config } from '@dvag/te-template-manager-web-component';
import { apiConfig } from '../config';
import { mappingId } from '../data';
import { PARAM_FORM_PRESELECTED_TEMPLATE_ID, PARAM_FORM_TARIFIERUNG_NEU, PATH_FORM_PAGE1 } from '../navigation/routes';
import { StateService } from '../service/state/state.service';

@Component({
  selector: 'app-warenkorb',
  templateUrl: './warenkorb.component.html',
  styleUrls: ['./warenkorb.component.scss'],
})
export class WarenkorbComponent implements AfterViewInit {
  @ViewChild('templateManager') templateManager: ElementRef;

  templateManagerConfig: Config = {
    api: apiConfig,
    mapping: mappingId,
    warenkorb: true,
    tarifierungNeu: false,
  };

  preselectedTemplateId: string | undefined = undefined;

  constructor(private router: Router, private stateService: StateService) {}

  ngAfterViewInit() {
    this.templateManager.nativeElement.addEventListener('templatePreselected', this.templatePreselected.bind(this));
  }

  templatePreselected(event: CustomEvent<string>) {
    this.preselectedTemplateId = event.detail;
  }

  tarifierungNeu() {
    this.router.navigate([PATH_FORM_PAGE1], {
      queryParams: {
        [PARAM_FORM_PRESELECTED_TEMPLATE_ID]: this.preselectedTemplateId,
        [PARAM_FORM_TARIFIERUNG_NEU]: true,
      },
    });
  }

  tarifierungForfuehren() {
    this.router.navigate([PATH_FORM_PAGE1], {
      queryParams: {
        [PARAM_FORM_PRESELECTED_TEMPLATE_ID]: this.preselectedTemplateId,
      },
    });
  }
}
